import React from "react";

const CartStep = (props) => {
  const { isCartPageActive, isCheckoutActive, isOrderActive, isDone } = props;
  return (
    <div className="cart_step_order">
      <div className="sec_container">
        <ul className="cart_step_container">
          <li className={isDone ? "done" : ""}>
            <i className={isCartPageActive ? "fa fa-check checkData" : "fa fa-check"}></i>
            <span className={isCartPageActive ? "bold_text" : ""}>
              Bags
            </span>
          </li>
          <li className={isDone ? "done" : ""}>
          <i className={isCheckoutActive ? "fa fa-check checkData" : "fa fa-check"}></i>
            <span className={isCheckoutActive ? "bold_text" : ""}>
              Address
            </span>
          </li>
          <li className={isDone ? "done" : ""}>
          <i className={isOrderActive ? "fa fa-check checkData" : "fa fa-check"}></i>
            <span className={isOrderActive ? "bold_text" : ""}>
              Payments
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CartStep;
