import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import{ BASE_URL} from '../config';
import Loading from "../pages/Loading"; 

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
};

const Testimonial = ({ testimonail }) => {
    const [loading, setLoading] = useState(true);
    const [dataTest, setDataTest] = useState([]);

    useEffect(() => {
        if (testimonail && testimonail.testimonials) {
            setDataTest(testimonail.testimonials);
            setLoading(false);
        }
    }, [testimonail]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="section-padding-two">
            <div className="section-heading-wrapper">
                <h1 className="heading-title">Testimonial</h1>
                <p className="paratitle">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Repellendus sed officiis explicabo. Quibusdam blanditiis quis,
                    officia quos placeat illum consectetur corrupti nisi suscipit?
                    Minima doloremque odit tempora quia eligendi laudantium!
                </p>
            </div>
            <div className="testimonial-slider mt-5">
                <Slider {...settings}>
                    {dataTest.map((item) => (
                        <div className="testimonial" key={item.id}>
                            <div className="testimonial-content">
                                <img src={`${BASE_URL}/${item.image}`} alt="banner"/>
                                <h3>{item.title}</h3>
                                {item.description}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Testimonial;
