// 
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

export default function RangeFilter(props) {
const{rangevalue,setRangeValue}=props

return (
<>
<div className="price-ranger">

    <RangeSlider min={0} max={10000} step={100} value={rangevalue} onInput={setRangeValue} />
    <h2 className="my-3">
        {rangevalue[0]} to this range {rangevalue[1]}
      </h2>
</div>
</>

);
}