import React from 'react';
import { Navigate,useLocation } from 'react-router-dom';
import useStore from '../zustand';

const ProtectedRoute = ({ children }) => {
    const token = useStore(state => state.token);
   
    const location=useLocation();
   
    if (!token) {
        return <Navigate to="/login" state={{from :location}} replace />; 
    }

    return children;
};

export default ProtectedRoute;
