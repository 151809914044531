import React,{useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { REACT_BASE_URL } from "../config";

const ChangePasssword = () => {
  const [values,setValues]=useState({
newpassword:"",
confirmpassword:""
})
const handleChange=(e)=>{
    setValues({...values,[e.target.name]:e.target.value})
}
const handleSubmit=async()=>{
  console.log("values submit----------->",values);
  const { newpassword, confirmpassword } = values;
  if (!values.newpassword.trim() || !values.confirmpassword.trim()) {
    toast.error("Please fill in all password fields");
    return;
}
  if (newpassword ===confirmpassword) {
    try {
      const response = await axios.post(`${REACT_BASE_URL}/forget-password-reset`, {
        newPassword: newpassword
      });
      if (response.status === 200) {
        toast.success("Password changed successfully");
      } else {
        toast.error("Failed to change password");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to change password");
    }
  } else {
    toast.error("Passwords do not match");
  }
}
  return (
    <>
       <Header />
      <section className="section-padding">
        <h1 className="text-center">Change Password</h1>
        <div className="forget-wrapper">
         
            <div className="form-data my-4">
              <label htmlFor="email">New Password</label>
              <input
                type="password"
                className="form-control my-2"
                name="newpassword"
                value={values.newpassword}
                onChange={handleChange}
                placeholder="Enter Your New Passowrd"
              />
            </div>
            <div className="form-data my-4">
              <label htmlFor="confirmpassword">Confirm Password</label>
              <input
                type="password"
                className="form-control my-2"
                name="confirmpassword"
                value={values.confirmpassword}
                onChange={handleChange}
                placeholder="Enter Your Confirm Password"
              />
            </div>
            <div className="btn-wrapper">
                <button className="btns" onClick={handleSubmit}>Set Password</button>
            </div>
        
        </div>
      </section>
      <ToastContainer autoClose={800} className="toastData"/>
      <Footer />
    </>
  )
}

export default ChangePasssword
