import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { REACT_BASE_URL } from "../config";
import Loader from "../pages/Loading";
import Header from "./Header";
import Footer from "./Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStore from "../zustand/index";
const Profile = ({fetchProfile}) => {

  const [isLoading, setIsLoading] = useState(true);
  const formValues={
    name:"",
    l_name:"",
    email:""

  }
  const [formData,setFormData]=useState(formValues);
  const navigate = useNavigate();
  const token = useStore(state => state.token);
  const handleChange =  (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (!token) {
          navigate("/login");
          return;
        }

        const response = await axios.post(`${REACT_BASE_URL}/get-user-profile`, null,{
          headers: {
            Authorization: `Bearer ${token}`,
            Accepts:"application/json"
          },
        });
    
        if (response.status === 200) {
     
          setFormData({
            name: response.data.data.name,
            l_name: response.data.data.l_name,
            email: response.data.data.email
          });
        }
      } catch (error) {
        console.error("Fetch user profile error:", error);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchUserProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const  handleSubmit=async (e) => {
    e.preventDefault()
    setIsLoading(true);
    console.log("runing data of submit button");

    try{
      
      let response=await axios.post(`${REACT_BASE_URL}/update-auth-profile`,formData,{
        headers: {
          Authorization: `Bearer ${token}`,
          Accepts:"application/json"
        },
      });

        console.log("response==============>",response);
        // setFormData(response?.data);
      toast.success(response?.data?.message);
      fetchProfile();
      setIsLoading(false);
    }catch(error){
      toast.error(error);
      setIsLoading(false);
    }

    
  }
  return (
    
    <>
    <Header/>
      {isLoading ? ( 
        <div>
          <Loader />
        </div>
      ) : (
        <>
         
          {formData ? ( 
            <div className="profile-data-containe section-padding">
               <h2 className="text-center">User Profile </h2>
                <div className="login-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="form-data my-4">
            <label htmlFor="f_name">First Name</label>
            <input
              type="text"
              className="form-control my-2"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder='Enter your First Name'
            />
          </div>
          <div className="form-data my-4">
            <label htmlFor="l_name">Last Name</label>
            <input
              type="text"
              className="form-control my-2"
              name="l_name"
              value={formData.l_name}
              onChange={handleChange}
              placeholder='Enter your Last Name'
            />
          </div>
          <div className="form-data my-4">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control my-2"
              name="email"
              value={formData.email}
              placeholder="Enter Your Email"
              onChange={handleChange}
            />
          </div>
          
          <div className="btn-wrapper" >
            <button type="submit" className="btns btn-md" style={{margin:"0 auto"}}>
              Updated&nbsp;Profile
            </button>
          </div>
        </form>
      </div>
            </div>
          ) : (
            <p className="text-center">No user data available.</p>
          )}
        </>
      )}
       <ToastContainer autoClose={800} className="toastData"/>
      <Footer/>
    
    </>
  );
};

export default Profile;
