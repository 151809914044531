import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { REACT_BASE_URL } from '../config';
import useStore from "../zustand/index";

const Register =  () => {


  const formValues={
    id:new Date().getTime(),
    f_name: '',
    l_name: '',
    email: '',
    password: '',
  }
  const [values, setValues] = useState(formValues);
  const token = useStore(state => state.token);
  const handleChange =  (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const re = /\S+@\S+\.\S+/;
    const email=values.email;
    if(!values.f_name.trim()  || !values.l_name.trim()  || !values.email.trim()  || !values.password.trim()){
      toast.error("Please Fill the all Fields");
      return
    }
    if ((values.password.length <= 8)) {
      toast.error("Password must be at least 8 characters");
      return;
    }
    if(values.f_name <=10 || values.l_name <= 10){
      toast.error("The character must be less then 10");
      return
    }
    if(!re.test(email)){
      toast.error("invalid email ");
      return;
    }
   
    try {
     
      const response = await axios.post(`${REACT_BASE_URL}/register`, values,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        setValues(formValues); 
        
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  return (
    <>
    <Header/>
     <section className="section-padding">
       <h1 className="text-center">Register</h1>
      <div className="login-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="form-data my-4">
            <label htmlFor="f_name">First name</label>
            <input
              type="text"
              className="form-control my-2"
              name="f_name"
              value={values.f_name}
              onChange={handleChange}
              placeholder='Enter your First Name'
            />
          </div>
          <div className="form-data my-4">
            <label htmlFor="l_name">Last name</label>
            <input
              type="text"
              className="form-control my-2"
              name="l_name"
              value={values.l_name}
              onChange={handleChange}
              placeholder='Enter your Last Name'
            />
          </div>
          <div className="form-data my-4">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control my-2"
              name="email"
              value={values.email}
              placeholder="Enter Your Email"
              onChange={handleChange}
            />
          </div>
          <div className="form-data my-4">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control my-2"
              name="password"
              value={values.password}
              onChange={handleChange}
              placeholder="Enter Your Password"
            />
          </div>
          <div className="btn-wrapper">
            <button type="submit" className="btns btn-md">
              Register
            </button>
          </div>
        </form>
      </div>
    </section>
    <ToastContainer autoClose={800} className="toastData"/>
    <Footer/>
    </>
   
  );
};

export default Register;
