import React,{useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { REACT_BASE_URL } from "../config";
// import { useNavigate } from "react-router";
const ForgetPassword = () => {
    const [values,setValues]=useState({
        email:""
    })
    const handleChange=(e)=>{
        setValues({...values,[e.target.name]:e.target.value})
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const re = /\S+@\S+\.\S+/;
        const email=values.email;
        if (!values.email.trim()) {
          toast.error("Please fillthr Email.");
          return;
        }
        if(!re.test(email)){
          toast.error("invalid email ");
          return;
        }
       
        try {
            const requestData={
                email:values.email
            }
          const response = await axios.post(`${REACT_BASE_URL}/forget-password-send-email`, requestData);
            console.log(response.data);
          if (response.status === 200) {
           
            toast.success(response.data.message);
           
          } else {
            toast.error('An error occurred. Please try again later.');
            // navigate("/login");
          }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          toast.error('An error occurred. Please try again later.');
        }
      };
  return (
    <>
      <Header />
      <section className="section-padding">
        <h1 className="text-center">Forget Password</h1>
        <div className="forget-wrapper">
         
            <div className="form-data my-4">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control my-2"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter Your Email"
              />
            </div>
            <div className="btn-wrapper">
                <button className="btns" onClick={handleSubmit}>Send Email</button>
            </div>
        
        </div>
      </section>
      <ToastContainer autoClose={800} className="toastData"/>
      <Footer />
    </>
  );
};

export default ForgetPassword;
