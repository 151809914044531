import React from 'react'
import img3 from "../assets/img/banner1.jpg";

const MidSection = () => {
  return (
    <>
      {/* <div className="section-padding"> */}
      <img src={img3} alt="banner" className='banner-data'/>
    {/* </div> */}
    </>
  )
}

export default MidSection
