import React, { useState,useEffect } from "react";
import { REACT_BASE_URL } from "../config";
import useStore from "../zustand/index";
import axios from "axios";
const ModalTwo = ({ isOpen, onClose, items, setItems,dataValue,setDataValues,formValues,getAddressList }) => {
  const [error,setError]=useState({});
  const token = useStore(state => state.token);
  console.log("toekn---------------->",token);
  useEffect(() => {
  getAddressList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);


  const handleChange =  (e) => {
    const {name,value}=e.target
    setDataValues({ ...dataValue, [name]:value });
    setError({...error,[name] : ""})
  };
const formSubmit = async (e) => {
  e.preventDefault();


  const validationErrors={};
  if (!dataValue.name) {
    validationErrors.name = "Name is required";
  }
  if (!dataValue.email) {
    validationErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(dataValue.email)) {
    validationErrors.email = "Invalid email format";
  }
  if (!dataValue.phone) {
    validationErrors.phone = "Mobile number is required";
  } else if (!/^\d{10}$/.test(dataValue.phone)) {
    validationErrors.phone = "Invalid mobile number format";
  }
  if (!dataValue.state) {
    validationErrors.state = "State is required";
  }
  if (!dataValue.city) {
    validationErrors.city = "City is required";
  }
  if (!dataValue.locality) {
    validationErrors.locality = "Locality is required";
  }
  if (!dataValue.address) {
    validationErrors.address = "Address is required";
  }
  if (!dataValue.pincode) {
    validationErrors.pincode = "Pin code is required";
  } else if (!/^\d{6}$/.test(dataValue.pincode)) {
    validationErrors.pincode = "Invalid pin code format";
  }
  if (!dataValue.landmark) {
    validationErrors.landmark = "Landmark is required";
  }
  if (Object.keys(validationErrors).length > 0) {
    setError(validationErrors);
    return;
  }
  try {
    let response;
    if (dataValue.Id) {
    
      const postData= { ...dataValue, id: dataValue.Id };
       response = await axios.post(`${REACT_BASE_URL}/user-auth-address-update`,postData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accepts: "application/json"
        }
      });
      console.log("response==========>",response);
      if(response.status===200){
        getAddressList();  
        setItems(items.map(item => item.Id === dataValue.Id ? { ...item, data: dataValue } : item));
      }
     
    } else {
      const postData={
        name:dataValue.name,
        email:dataValue.email,
        phone:dataValue.phone,
        state:dataValue.state,
        city:dataValue.city,
        landmark:dataValue.landmark,
        pincode:dataValue.pincode,
        address:dataValue.address
      }
       response = await axios.post(`${REACT_BASE_URL}/user-add-address`, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accepts: "application/json"
        }
      
      });
      if(response.status===200){
        getAddressList();
        setItems([...items, { id: response.data.Id, data: dataValue }]);
      }
    }
    setDataValues({ ...formValues });
    onClose();
  } catch (error) {
    console.error("Error:", error);
  }
  setDataValues({ id: null, name: '', description: '' });
  onClose();
};

  if (!isOpen) return null;
  return (
    <>
      <div className="modal-overlay">
        <div className="modals">
          <button className="close-button" onClick={onClose}>
            X
          </button>
          <h2 className="text-center"> { dataValue.Id ? "Update The Address" : "Add New Address"}</h2>
          <div className="form-wrapper">
            <form onSubmit={formSubmit}>
              <div className="form-data-container">
                <div className="form-group">
                  <label htmlFor="exampleInputName">Name</label>
                  <input
                    type="text"
                    className={`form-control ${error.name && "is-invalid"}`}
                    name="name"
                    placeholder="Enter Your Name"
                    value={dataValue.name}
                    onChange={handleChange}
                    id="exampl1"
                  />
                    {error.name && <div className="invalid-feedback">{error.name}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail">Email</label>
                  <input
                    type="text"
                    className={`form-control ${error.email && "is-invalid"}`}
                    name="email"
                    placeholder="Enter Your Email Address"
                    value={dataValue.email}
                    onChange={handleChange}
                    id="exampl2"
                  />
                    {error.email && <div className="invalid-feedback">{error.email}</div>}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputNumber">Mobile Number</label>
                <input
                  type="phone"
                  className={`form-control ${error.phone && "is-invalid"}`}
                  name="phone"
                  placeholder="Enter Your Mobile Number"
                  value={dataValue.phone}
                  onChange={handleChange}
                  id="exampl3"
                />
                   {error.phone && <div className="invalid-feedback">{error.phone}</div>}
              </div>

              <div className="form-data-container">
                <div className="form-group">
                  <label htmlFor="exampleInputNumber">State</label>
                  <input
                    type="text"
                    className={`form-control ${error.state && "is-invalid"}`}
                    name="state"
                    placeholder="Enter Your State"
                    value={dataValue.state}
                    onChange={handleChange}
                    id="exampl3"
                  />
                  {error.state && <div className="invalid-feedback">{error.state}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputNumber">City</label>
                  <input
                    type="text"
                    className={`form-control ${error.city && "is-invalid"}`}
                    name="city"
                    placeholder="Enter Your City"
                    value={dataValue.city}
                    onChange={handleChange}
                    id="exampl4"
                  />
                   {error.city && <div className="invalid-feedback">{error.city}</div>}
              
                </div>
              </div>
              <div className="form-data-container">
                <div className="form-group">
                  <label htmlFor="exampleInputNumber">Locality</label>
                  <input
                    type="text"
                    className={`form-control ${error.locality && "is-invalid"}`}
                    name="locality"
                    placeholder="Enter Your Locality"
                    value={dataValue.locality}
                    onChange={handleChange}
                    id="exampl4"
                  />
                    {error.locality && <div className="invalid-feedback">{error.locality}</div>}
              
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputNumber">Address</label>
                  <textarea
                    className={`form-control ${error.address && "is-invalid"}`}
                    name="address"
                    placeholder="Enter Your Address"
                    value={dataValue.address}
                    onChange={handleChange}
                    id="exampl5"
                    rows="12"
                    cols="12"
                  />
                     {error.address && <div className="invalid-feedback">{error.address}</div>}
                </div>
              </div>
              <div className="form-data-container">
                <div className="form-group">
                  <label htmlFor="exampleInputNumber">Pin Code</label>
                  <input
                    type="text"
                    className={`form-control ${error.pincode && "is-invalid"}`}
                    name="pincode"
                    placeholder="Enter Your Pin Code"
                    value={dataValue.pincode}
                    onChange={handleChange}
                    id="exampl6"
                  />
                     {error.pincode && <div className="invalid-feedback">{error.pincode}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputNumber">LandMark</label>
                  <input
                    type="text"
                    className={`form-control ${error.landmark && "is-invalid"}`}
                    name="landmark"
                    placeholder="Enter Your LandMark"
                    value={dataValue.landmark}
                    onChange={handleChange}
                    id="example7"
                  />
                   {error.landmark && <div className="invalid-feedback">{error.landmark}</div>}
                </div>
              </div>
              <div className="btn_submit">
                <button type="submit" className="btns">
                 { dataValue.Id ? "Update" : "Submit"}
                 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ModalTwo;
