import React, { useState } from "react";
import img from "../assets/img/pexels-anete-lusina-5247989.jpg";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { REACT_BASE_URL } from "../config";

const ContactUs = () => {
  const formValues = {
    fname: "",
    lname: "",
    phone: "",
    email: "",
  };
  
  const [values, setValues] = useState(formValues);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const re = /\S+@\S+\.\S+/;
    const email = values.email;
    
    if (!values.fname.trim() || !values.lname.trim() || !values.email.trim() || !values.phone.trim()) {
      toast.error("Please Fill in all Fields");
      return;
    }

    if (values.phone.length < 8) {
      toast.error("Phone number must be at least 8 characters");
      return;
    }

    if (!re.test(email)) {
      toast.error("Invalid email");
      return;
    }

    try {
      const response = await axios.post(`${REACT_BASE_URL}/contact-us-query-send`, values);
      
      if (response.status === 200) {
        toast.success(response.data.message);
        setValues(formValues); 
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
    }
  };

  return (
    <>
      <Header />
      <div className="section-padding contact-form">
      <h1 className="text-center mb-20">Contact Us</h1>
        <div className="container">
         
          <div className="row">
            <div className="col-lg-6">
              <img
                src={img}
                alt="datOne"
                className="align-self-center border-img-wrapper"
              />
            </div>
            <div className="col-lg-6 ">
              <form onSubmit={handleSubmit} className="form-data-wrapper">
                <div className="form-data mt-2">
                  <label htmlFor="fname">First Name</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    name="fname"
                    placeholder="Enter Your First Name"
                    value={values.fname}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-data mt-2">
                  <label htmlFor="lname">Last Name</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    name="lname"
                    value={values.lname}
                    placeholder="Enter Your Last Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-data mt-2">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    name="phone"
                    placeholder="Enter Your Phone Number"
                    value={values.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-data mt-2">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    name="email"
                    placeholder="Enter Your Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
                <button className="btns mt-4" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <iframe
                title="Description of the iframe content"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d21056742.211671695!2d-97.15369!3d49.891235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s14735+32A+AVE+Surrey+V4P+1Z8+bc!5e0!3m2!1sen!2sca!4v1424618801986"
                width="100%"
                height="450"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer autoClose={800} className="toastData" />
    </>
  );
};

export default ContactUs;
