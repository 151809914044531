import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import StarRating from "./StarRating";
import { BASE_URL, REACT_BASE_URL } from "../config";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import useStore from "../zustand/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "../pages/Loading";
import RangeFilter from "../pages/RangeFilter";
import CategaryFilter from "../pages/CategaryFilter";

function ProductList({ xyz }) {
  const { search } = useLocation();
  const cartData = useStore((state) => state.cartData);
  const searchParams = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(true);
  const [searchData] = useState(searchParams.get("search") || null);
  const [rangevalue, setRangeValue] = useState([0, 1000]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const token = useStore((state) => state.token);
  const [filterProduct, setFilterProduct] = useState();
  const setCartData = useStore((state) => state.setCartData);
 

  const getCartData = async () => {
    try {
      const response = await axios.post(
        `${REACT_BASE_URL}/cart-list`,
        cartData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accepts: "application/json",
          },
        }
      );

      if (response.status === 200) {
        setCartData(response?.data?.cartData);
      }
    } catch (error) {
      console.log("error==============>", error);
    }
  };
  const handleAddCart = async (productId) => {
    try {
      const selectProduct = filterProduct.find(
        (product) => product.id === productId
      );
      if (!token) {
        toast("Please first login");
        return; 
      }
      const newItem = {
        prd_id: selectProduct.id,
        amount: selectProduct.price,
        qty: selectProduct.prd_qty,
      };

      let response = await axios.post(
        `${REACT_BASE_URL}/add-to-cart`,
        newItem,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accepts: "application/json",
          },
        }
      );
      if (response.status === 200) {
        setCartData(cartData,response?.data?.cartData);
        getCartData();
        toast("Item Added Successfully");
        console.log("Response after adding to cart:", response);
      }else if(token==null) {
        toast("Please first Login");
      }
    } catch (error) {
      console.log(error);
      toast(error.message);
    }
  };
  const fetchData = async (searchData) => {
    try {
      let response;
      const postData = { search: searchData };
      const filterData = {
        start_price: rangevalue[0],
        end_price: rangevalue[1],
        carId: selectedCategory,
      };

      if (searchData != null) {
        response = await axios.post(
          `${REACT_BASE_URL}/products-list`,
          postData
        );
        const productValue = response?.data?.products?.products?.data;

        setIsLoading(false);
        setFilterProduct(productValue);
      } else {
        response = await axios.post(
          `${REACT_BASE_URL}/products-list`,
          filterData
        );
        setIsLoading(false);
        setFilterProduct(response?.data?.products?.products?.data);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error fetching data");
    }
  };
  useEffect(() => {
    fetchData(searchData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);
  // useEffect(() => {
  //   getCartData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(()=>{
  // setSearchData(searchParams.get('search') || null);

  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[search,searchParams])
  const handleApply = () => {
    fetchData(searchData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          <ToastContainer autoClose={800} className="toastData" />
          {xyz && <Header />}
          <div className="section-padding" style={{ position: "relative" }}>
            <div className="container">
              <div className="row">
                <div className="filter-wrapper">
                  <div className="filter-left">
                    {xyz && (
                      <RangeFilter
                        rangevalue={rangevalue}
                        setRangeValue={setRangeValue}
                      />
                    )}
                    <div className="category-wrapper">
                      {xyz && (
                        <CategaryFilter
                          setSelectedCategory={setSelectedCategory}
                          selectedCategory={selectedCategory}
                        />
                      )}
                    </div>
                  </div>
                  {xyz && (
                    <div className="filter-right">
                      <div className="btn-filter">
                        <button className="btns" onClick={handleApply}>
                          Apply
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {filterProduct?.map((product) => (
                  <>
                   
                    <div className="col-lg-4" key={product?.id}>
                      <div className="product-list-wrapper">
                        <NavLink to={`/productdetail/${product.slug}`}>
                          {/* <div className="badge-wrapper">
                        {product.isOnSale && (
                          <span className="badge-sale">SALE</span>
                        )}
                        {product.isSoldOut && (
                          <span className="sold-out">SOLD OUT</span>
                        )}
                      </div> */}
                          <div className="overlays"></div>
                          <div className="img-wrapper">
                            <img
                              src={`${BASE_URL}/${product?.image}`}
                              alt="dat12"
                            />
                          </div>
                          <div className="product-list-content">
                            <div className="icon-product">
                              <StarRating rating={product?.rating} />
                              {}
                            </div>
                            <h3 className="mt-1">{product?.prd_name}</h3>
                            <div className="price-containner">
                              {product.price && (
                                <span className="price">
                                  Rs{product?.price}
                                </span>
                              )}
                              {/* {product.discounted_price && (
                            <span className="price">
                              {product.discounted_price}
                            </span>
                          )} */}
                              &nbsp;
                              <span className="cut-price ml-3">
                                Rs{product?.discounted_price}
                              </span>
                            </div>
                          </div>
                        </NavLink>
                        <div className="button-section">
                          {/* {product.status && (
                        <button className="btns" onClick={handleSold}>
                          Sold-Out
                        </button>
                      )} */}
                          {product.status && (
                            <button
                              className="btns"
                              onClick={() => {
                                // try {
                                //   const isProductExist = cartData.filter(
                                //     (item) => item.id === product?.id
                                //   );
                                //   if (isProductExist.length > 0) {
                                //     toast("Product Already Exist");
                                //   } else {
                                handleAddCart(product.id);
                                //   }
                                // } catch (error) {
                                //   toast(error.message);
                                // }
                              }}
                            >
                              Add to cart{" "}
                              <i className="fa fa-shopping-cart"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          {xyz && <Footer />}
        </>
      )}
    </>
  );
}

export default ProductList;
