
import "../src/assets/css/style.css";
import Router from './route/Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 

  function App() {
 
  return (
    <>
   <Router/>
    </>
  );
}

export default App;
