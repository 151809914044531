import React,{} from "react";
import Header from "../components/Header";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import CartStep from "./CartStep";
import useStore from "../zustand/index";
import {BASE_URL,REACT_BASE_URL} from '../config';
import img1 from "../assets/img/sfer.png";
import axios from "axios";
const Cart = () => {
  const cartData = useStore((state) => state.cartData);
  const setCartData = useStore((state) => state.setCartData);
  const token = useStore(state => state.token);
  console.log("cartData================>",cartData);
  const shippingCosts=70
  const navigate=useNavigate();
  const fetchData = async () => {
    try {
      const response = await axios.post(`${REACT_BASE_URL}/cart-list`,cartData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accepts: "application/json"
        }
      });
      console.log("response===========cart",response);
      if (response.status === 200) {
        const updatedCartData = response?.data?.cartData;
        setCartData(updatedCartData);
      }
    } catch (error) {
      console.log("error==============>", error);
    }
  };
  const removeItem=async(userId)=>{
   try{
    const productData={
      id:userId
    }
    const response=await axios.post(`${REACT_BASE_URL}/remove-from-cart`,productData,{
      headers: {
        Authorization: `Bearer ${token}`,
        Accepts:"application/json"
      }})
      if(response.status===200){
        // setCartData(response?.data?.data);
        fetchData();
      }

   }catch(error){
    console.log(error);
   }
    

    // const updatedCartData = cartData.filter((item) => item.id !== id);
    // setCartData(updatedCartData);
  }


  // useEffect(() => {
  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [setCartData]); 

  function calculatePrice(){
    let TotalPrice=0;
    cartData.forEach((product)=>{
      TotalPrice+=Math.round(product?.qty * product?.amount);
    })
    return TotalPrice;
  }
  function grandTotal(){
    let grandTotal = 0
     grandTotal =   shippingCosts + calculatePrice()
    return grandTotal
  }
  const orderPlace=()=>{
    navigate("/address");
  }
  return (
    <>
      <Header/>
      <CartStep isCartPageActive={true}/>
      <div className="section-padding">
      
        <div className="cart-checkout-container">
          {cartData.length !==0 ? (  <>
            <div className="checkout-summary">   
            <div className="cart-wrapper-content">
            <h3 className="font-weight-bold heading-cart">Checkout Summary</h3>
            <p className="font-weight-bold"><span className="cartValue">My Bags:</span>({cartData.length})</p>
            <p className="font-weight-bold"><span className="cartValue">₹{calculatePrice()}.00</span></p>
            </div> 
              {
                cartData?.map((product,index)=>(
                  <div key={index} className="wrapper-cart-data">
                  <div><img src={`${BASE_URL}/${product.image}`} alt="data" className="image_product"/></div>
                  <div>{product.prd_name}</div>
                  <div>{product.qty}</div>
                  <div>{product.amount}</div>
                  <div><i className="fa fa-times data-remove-icon" aria-hidden="true" onClick={()=>removeItem(product.id)}></i></div>
                  </div>
                ))
              }
            </div>
            <div className="checkout-coupen-list">
                <h1>Offers & Coupon codes</h1>
                  <div className="card-form-data">
                      <input type="text" className="form-control h-15"/>
                      <button className="btns">Apply</button>
                  
                  </div>
                  <p>Give free shopping Order Free 500Rs</p>
                  <div className="order_summary">
                    
                      <div>
                        <h1>Order Summary</h1>
                        <div className="order-summary">
                        <p>Bag Total (Inclusive of all taxes)</p>
                         <p>₹{calculatePrice()}.00</p> 
                        </div>
                        <div className="shipping-cost">
                        <p>Shipping Cost</p>
                         <p>₹{shippingCosts}.00</p> 
                        </div>
                        <div className="payable-data">
                        <p>Payable Amount</p>
                          <p>₹{grandTotal()}</p>  
                        </div>
                      </div>
                      <div>
                      </div>
                  </div>
                  <div>
                  <button className="btns w-100" onClick={orderPlace}>Place Order</button>
                  </div>
            </div>
          
          </>) :<>
          <div className="mx-auto my-auto">
               {/* <p className="text-center">Product Not Found</p>  */}
               <img src={img1} alt="current_data"/>
          </div>
          </>}
           
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Cart;
