import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { REACT_BASE_URL } from "../config";
import { NavLink } from "react-router-dom";
import useStore  from "../zustand/index"; // Importing Zustand store

const Login = () => {
  const formValues = {
    email: "",
    password: "",
  };

  const [values, setValues] = useState(formValues);
  const setToken = useStore(state => state.setToken);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const re = /\S+@\S+\.\S+/;
    const email = values.email;

    if (!values.email.trim() || !values.password.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }
    if (!re.test(email)) {
      toast.error("Invalid email");
      return;
    }
    
    try {
      const response = await axios.post(`${REACT_BASE_URL}/login`, values);
      console.log("response===========>",response);
      if (response.status === 200) {
        setToken(response?.data?.token);
        toast.success(response?.data?.message);
        setValues(formValues);
        navigate("/");
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Invalid email or password.');
      } else {
        console.error('There was a problem with the fetch operation:', error);
        toast.error('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <>
      <Header />
      <section className="section-padding">
        <h1 className="text-center">LogIn</h1>
        <div className="login-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="form-data my-2">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control my-2"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter Your Email"
              />
            </div>
            <div className="form-data my-4">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control my-2"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Enter Your Password"
              />
            </div>
            <NavLink to="/forget-password" className="forget-text">
              forgot password?
            </NavLink>
            <div className="btn-wrapper">
              <button type="submit" className="btns btn-md my-3">
                Login
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
      <ToastContainer autoClose={100} className="toastData" />
    </>
  );
};

export default Login;
