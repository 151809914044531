import React,{useState,useEffect} from "react";
import Banner from "../components/Banner";
import Looking from "../components/Looking";
import ShopSection from "../components/ShopSection";
import MidSection from "../components/MidSection";
import BestSeller from "../components/BestSeller";
import Blog from "../components/Blog";
import Testimonial from "../components/Testimonial";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import Loader from "./Loading";
import { REACT_BASE_URL } from "../config";



const Home = () => {
  const [stages,setStages]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${REACT_BASE_URL}/home`);
        if(response.status===200){
          setIsLoading(false);
          setStages(response.data.data); 
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
 
  return (
    <>
    {
      isLoading ? <div><Loader/></div> : <> <Header />
      <Banner  bannerData={stages}/>
       <ShopSection shopData={stages}/>
      <Looking nectorData={stages}/>
      <MidSection/>
      <BestSeller bestSeller={stages}/>
      <Blog blogValue={stages}/>
      <Testimonial testimonail={stages}/>
      <Footer />
      </>
    }
     
    </>
  );
};

export default Home;
