import React, { useState,useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import CartStep from "./CartStep";
import ModalTwo from "../components/ModalTwo";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REACT_BASE_URL } from "../config";
import useStore from "../zustand/index";
import axios from "axios";
const Address = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formValues = {
    // id: null,
    name: "",
    email: "",
    phone: "",
    state: "",
    city: "",
    locality: "",
    address: "",
    pincode: "",
    landmark: "",
  };
  const [dataValue, setDataValues] = useState({...formValues});
  const [items, setItems] = useState(null);
  const token = useStore(state => state.token);

  const Navigate = useNavigate();
  const backButton = () => {
    Navigate("/cart");
  };

const proceedPayment = () => {
  console.log("items:", items);
  if (!items || items.length === 0) {
    toast.error("Please fill in all address fields");
  } else {
    const isAnyEmpty = items.some(item => {
      return Object.values(item).some(value => value === "");
    });
    if (isAnyEmpty) {
      toast.error("Please fill in all address fields");
    } else {
      Navigate("/payment");
    }
  }
}
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setDataValues("");

  };

  const getAddressList = async() =>{
    try{
      const response=await axios.get(`${REACT_BASE_URL}/get-auth-address`, { headers: {
        Authorization: `Bearer ${token}`,
        Accepts:"application/json"
      }});
   if(response.status===200){
    setItems(response.data?.data);
   }
    }catch(error){
      console.log("The value of error",error);
    }
  }
useEffect(()=>{
  getAddressList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])
  const handleEdit = (id) => {
    const selectedItem = items.find((item) => item.Id === id);
    setDataValues(selectedItem);
    setIsModalOpen(true);
  };

  const deleteMethod = async (userId) => {
    try {
      const response = await axios.get( 
        `${REACT_BASE_URL}/user-delete-address/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      if(response.status===200){
        setItems(response?.data?.data);
        toast.success(response?.data?.message);
        getAddressList();
      }

     
    } catch (error) {
      toast.error(error.response.data.message); 
      console.log("The value of error", error);
    }
  };
  const handleDelete = (id) => {
    deleteMethod(id);
  };
  return (
    <>
      <Header />
      <CartStep isCheckoutActive={true} />
      <div className="section-padding address_container">
        <div className="address_wrapper">
          <div>
            <span className="address-span">
              <button onClick={openModal}>+</button>
            </span>
            <p className="address-para">Add New Address</p>
          </div>
          <div className="address-form-contents">
            {items?.length > 0 && items !== undefined && items !== null && (
              <>
                {items?.map((formData) => {
                
                  return (
                    <div className="address-form-data" key={formData.Id}>
                      <div>
                        <h1>{formData.name}</h1>
                        <p>{formData.address}</p>
                        <p>{formData.email}</p>
                        <p>{formData.phone}</p>
                        <p>{formData.pincode}</p>
                        <p>{formData.state}</p>
                        <p> {formData.landmark}</p>
                      </div>
                      <div className="address-icon-wrapper">
                        <i
                          className="fa fa-pencil"
                          aria-hidden="true"
                          onClick={() => handleEdit(formData.Id)}
                        ></i>

                        <i
                          className="fa fa-trash-o"
                          aria-hidden="true"
                          onClick={() => handleDelete(formData.Id)}
                        ></i>
                      </div>
                    </div>
                  );
                })}
              </>
            )} 
          </div>
        </div>
        <ModalTwo
          isOpen={isModalOpen}
          onClose={closeModal}
          setItems={setItems}
          items={items}
          setDataValues={setDataValues}
          dataValue={dataValue}
          getAddressList={getAddressList}
          formValues={formValues}
        />
        <div className="add_inner_button">
          <div>
            <button className="btns" onClick={backButton}>
              Back
            </button>
          </div>
          <div>
            <button className="btns"  onClick={proceedPayment}>Proceed to Payment</button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={800} className="toastData"/>
      <Footer />
    </>
  );
};

export default Address;
