import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Terms = () => {
  return (
    <>
    <Header/>
     <div className='section-padding'>
        <div className='container'>
            <div className='col-lg-12'>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Similique, accusantium, quae ducimus nostrum, veniam cupiditate
                officiis iure consequatur eaque libero quasi repellendus!
                Adipisci quisquam dolores officia aliquam fuga neque libero.</p>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Similique, accusantium, quae ducimus nostrum, veniam cupiditate
                officiis iure consequatur eaque libero quasi repellendus!
                Adipisci quisquam dolores officia aliquam fuga neque libero.</p>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Similique, accusantium, quae ducimus nostrum, veniam cupiditate
                officiis iure consequatur eaque libero quasi repellendus!
                Adipisci quisquam dolores officia aliquam fuga neque libero.</p>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Similique, accusantium, quae ducimus nostrum, veniam cupiditate
                officiis iure consequatur eaque libero quasi repellendus!
                Adipisci quisquam dolores officia aliquam fuga neque libero.</p>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Similique, accusantium, quae ducimus nostrum, veniam cupiditate
                officiis iure consequatur eaque libero quasi repellendus!
                Adipisci quisquam dolores officia aliquam fuga neque libero.</p>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Similique, accusantium, quae ducimus nostrum, veniam cupiditate
                officiis iure consequatur eaque libero quasi repellendus!
                Adipisci quisquam dolores officia aliquam fuga neque libero.</p>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Similique, accusantium, quae ducimus nostrum, veniam cupiditate
                officiis iure consequatur eaque libero quasi repellendus!
                Adipisci quisquam dolores officia aliquam fuga neque libero.</p>
                <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Similique, accusantium, quae ducimus nostrum, veniam cupiditate
                officiis iure consequatur eaque libero quasi repellendus!
                Adipisci quisquam dolores officia aliquam fuga neque libero.</p>
            </div>
        </div>
    </div> 
    <Footer/>
    </>
  )
}

export default Terms
