import React from 'react'
import {BASE_URL }from '../config';
const StoryTwo = ({nectorStory}) => {
  const nectorValue=nectorStory.nectar;
  return (
    <>
      <div className='section-padding'>
        <div className='container'>
            <div className='row'>
               
                <div className='col-lg-6 align-self-center col-12'>
                <h1>The Founder Story</h1>
                  <p>{nectorValue.description}</p>
                </div>
                <div className='col-lg-6 mr-xl-top col-12'>
               
                    <img src={`${BASE_URL}/${nectorValue.image}`} alt="banner" className='border-img-wrapper' />
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default StoryTwo
