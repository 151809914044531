import React from 'react';
import ProductList from '../components/ProductList';

const Shop = () => {
  return (
    <>
     <ProductList xyz={true}/> 
    </>
  );
}

export default Shop;
