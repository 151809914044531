import React, { useState } from 'react'
import CartStep from './CartStep'
import Footer from '../components/Footer'
import Header from '../components/Header'
import useStore from "../zustand/index";
import visa from "../assets/img/visa rupay.jpg"
const Payment = () => {
  const cartData = useStore((state) => state.cartData);
  console.log("cartData---------------------->payment",cartData);
  const [hide,setHide]=useState(true);
  const shippingCosts=70;
  function calculatePrice(){
    let TotalPrice=0;
    cartData.forEach((product)=>{
      TotalPrice+=Math.round(product.qty * product.amount);
    console.log("TotalPrice",TotalPrice);
      
    })
    return TotalPrice;
  }
  function grandTotal(){
    let grandTotal = 0
     grandTotal =   shippingCosts + calculatePrice()
    return grandTotal
  }
  const prePaid=()=>{

    setHide(true);
  }
  const cashDelivery=()=>{
 
    setHide(false);

  }
  return (
    <div>
        <Header/>
        <CartStep isOrderActive={true}/>
        <div className='payment-section'>
          <div className='payment-wrapper'>
            <div className='payment-inner-wrapper'>
              
                <div>Select Payment Method</div>
                <div>
                  Total Payable 
                </div>
              
            </div>
            <div className='payment-container'>
            <div className='payment-cash-features'>
              <div>
                <button className={hide ? "btns"  : "btn-colors"} onClick={prePaid}>PrePaid</button>
              </div>
              <div className='img-source'>
               { hide && <img src={visa} alt="dat-props"/>} 
              </div>
              <div>
                <button className={hide ? "btn-colors" : "btns"} onClick={cashDelivery}>Cash On Delivery</button>
              </div>
              </div>
              <div className='payment-decription-wrapper'>
                    <div>
                      Order Summary
                    </div>
                    <div className='payment-total-price'>
                        <p>Balance Amount</p>
                        <p>Rs.{grandTotal()}</p>
                    </div>
                    <div className='payment-shpping-charges'>
                      <button className='btns mt-4'>Pay Now</button>
                    </div>
              </div>
            </div>
              
          </div>
        </div>
        <Footer/>
    
    </div>
  )
}

export default Payment
