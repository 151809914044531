import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {BASE_URL} from '../config';
const Banner = ({bannerData}) => {
  const banner=bannerData.banners;
  var settings = {
    dots: true, 
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 1000,

  };


  return (
    <>
   
    <div className='banner-section-wrapper'>
    
    <div className="banner-section">
   
      <div className="banner-wrapper">
      <Slider {...settings}>
        {
          banner?.map((item)=>(
            <div key={item.id}>
             <img src={`${BASE_URL}/${item.image}`} alt="banner" />
             
          </div>
          ))
        }
        </Slider>
      </div>
      
    </div> 
    </div>
    
    </>
  )
}

export default Banner
