import React,{useState,useEffect} from "react";
import Story from '../components/Story';
import StoryTwo from '../components/StoryTwo';
import MidSection from '../components/MidSection';
import Blog from '../components/Blog';
import Testimonial from '../components/Testimonial';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from "axios";
import Loader from "./Loading";
import { REACT_BASE_URL } from "../config";
const About = () => {
  const [stages,setStages]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${REACT_BASE_URL}/home`);
        if(response.status===200){
          setIsLoading(false);
          setStages(response.data.data); 
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
     {
      isLoading ? <div><Loader/></div> : <> <Header/>
        <Story storySlider={stages}/>
        <StoryTwo nectorStory={stages}/>
        <MidSection/>
        <Blog blogValue={stages}/>
      <Testimonial testimonail={stages}/>
        <Footer/>
        </>
     }
    </div>
    
  )
}

export default About
