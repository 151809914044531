import { create } from "zustand";
const useStore = create((set)=>({
    formDataArray: [],
    cartData:[],
    token: localStorage.getItem("token") || null,
    setCartData:(data)=>{
        set((state)=>({state,
            cartData: data,
           })) 
    },
    setToken: (token) => {
        set((state) => {
          localStorage.setItem("token", token);
          return { ...state, token: token };
        });
      },
      removeToken: () => {
        set((state) => {
          localStorage.removeItem("token");
          return { ...state, token: null ,cartData: []};
        });
      },
      
  
}))

export default useStore;