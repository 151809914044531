import React from 'react';
import {BASE_URL} from '../config';
import { NavLink } from "react-router-dom";

import Loading from '../pages/Loading';

const Blog = ({ blogValue }) => {
  const blogData = blogValue?.blogs || [];
  

  if (!blogData.length) {
    return (
      <div>
      <Loading/>
      </div>
    );
  }

  return (
    <>
      <div className="section-padding-two">
        <div className="section-heading-wrapper">
          <h1 className="heading-title">Blogs</h1>
          <p className="paratitle">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellendus
            sed officiis explicabo. Quibusdam blanditiis quis, officia quos
            placeat illum consectetur corrupti nisi suscipit? Minima doloremque
            odit tempora quia eligendi laudantium!
          </p>
        </div>
        <div className="blog-wrapper container mt-5">
          {blogData.map((item) => (
            <div className="blog-inner-wrapper" key={item.id}>
              <div className="blog-image">
                <img src={`${BASE_URL}/${item.image}`} alt="banner" />
              </div>
              <div className="data1">
                <NavLink to="#">{item.title}</NavLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blog;
