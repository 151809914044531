import React, { useEffect, useState } from 'react';
import axios from "axios";
import { REACT_BASE_URL } from '../config';

export default function CategoryFilter(props) {
  const { setSelectedCategory, selectedCategory } = props;
  const [categories, setCategories] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  async function fetchData() {
    try {
      const response = await axios.get(`${REACT_BASE_URL}/get-gategory`);
      const dataCategory = response.data.data;

      if (response.status === 200) {
        setCategories(dataCategory);
        if (!selectedCategory && dataCategory.length > 0) {
          setSelectedCategory(dataCategory[0].id);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
   
        const delay = Math.pow(2, retryCount) * 1000; 
       
        setTimeout(() => {
          setRetryCount(retryCount + 1);
        }, delay);
      } else {
        console.error("Error fetching data:", error);
      }
    }
  }

  useEffect(() => {
    fetchData();
       
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handleChangeData = (e) => {
    const dataValue = e.target.value;
    setSelectedCategory(dataValue);
  };

  return (
    <div className="category-wrapper">
      <select
        id="category"
        value={selectedCategory}
        onChange={handleChangeData}
      >
        {categories.map((item) => (
          <option key={item.id} value={item.id}>{item.name}</option>
        ))}
      </select>
    </div>
  );
}
