import {BASE_URL} from '../config';

const ShopSection = ({shopData}) => {
  const shop=shopData.shop
  return (
    <>
    <div className='section-padding'>
    <div className="section-heading-wrapper">
        <h1 className="heading-title">WELCOME TO HONEY SHOP</h1>
        <p className="paratitle">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellendus
          sed officiis explicabo. Quibusdam blanditiis quis, officia quos
          placeat illum consectetur corrupti nisi suscipit? Minima doloremque
          odit tempora quia eligendi laudantium!
        </p>
      </div>
      <div className="shop-wrapper mt-5">
          {
            shop?.map((item)=>(
                <div className="shap-inner-section" key={item.id}>
                  <img src={`${BASE_URL}/${item.image}`} alt="delcious" />
                    <p>{item.title}</p>
                </div>
            ))
          }
      </div>
    </div>
    </>
  )
}

export default ShopSection
