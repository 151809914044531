// ProductDetailPage.js

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import StarRating from "../components/StarRating";
import img7 from "../assets/img/feature1.png";
import img8 from "../assets/img/feature2.png";
import img9 from "../assets/img/feature4.png";
import img10 from "../assets/img/feature5.png";
import img11 from "../assets/img/discount.png";
import img12 from "../assets/img/flame.png";
import img13 from "../assets/img/growth.png";
import img14 from "../assets/img/drop.png";
import img15 from "../assets/img/honey.png";
import img16 from "../assets/img/swarm.webp";
import useStore from "../zustand/index";
import ProductList from "../components/ProductList";
import MidSection from "../components/MidSection";
import Testimonial from "../components/Testimonial";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { REACT_BASE_URL, BASE_URL } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductDetailPage = () => {

  const { slug } = useParams();

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const cartData = useStore((state) => state.cartData);
  const setCartData = useStore((state) => state.setCartData);

  const fetchData = async () => {
    try {
        const response = await axios.get(`${REACT_BASE_URL}/product-details/${slug}`);
        console.log("Response data:", response.data);

        if (response.data && response.data.data && response.data.data.product) {
            setProductDetail(response.data.data.product);
        } else {
            console.log("Invalid response data structure:", response.data);
        }
    } catch (error) {
        console.error("Error fetching data:", error.message);
    }
};
  useEffect(() => {
    
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const slider1Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: nav2,
  };

  const slider2Settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: nav1,
    dots: false,
    focusOnSelect: true,
  };
  const dataOne = [
    {
      img: `${BASE_URL}/${productDetail?.image}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.image}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.image}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.image}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.image}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.image}`,
    },
  ];
  const dataTwo = [
    {
      img: `${BASE_URL}/${productDetail?.other_prd_images}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.other_prd_images}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.other_prd_images}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.other_prd_images}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.other_prd_images}`,
    },
    {
      img: `${BASE_URL}/${productDetail?.other_prd_images}`,
    },
  ];
  const handleAddCart = (product) => {
    if (!product) return; 
  
    const isProductExist = cartData.some((item) => item.id === product?.id);
    if (isProductExist) {
      toast.error('Product Already Exists', {
        containerId: 'GlobalApplicationToast',
      });
    } else {
      setCartData([...cartData, product]); 
      toast.success('Success: Item added Successfully.', {
        toastId: 'unique-toast-success',
        containerId: 'GlobalApplicationToast',
      });
    }
  };
  
  return (
    <>
   {/* <ToastContainer autoClose={800} className="toastData" limit={1}/> */}
   <ToastContainer
        position="top-right"
        autoClose={800}
        closeOnClick
        pauseOnHover
        className="toastData"
        containerId="GlobalApplicationToast"
      />
      <Header />
      <div className="section-padding">
        <div className="product-detail-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-6 gx-4 col-12">
                <div className="image-slide-wrapper">
                  <div className="product-detail-left">
                    <Slider
                      {...slider1Settings}
                      ref={(slider) => setNav1(slider)}
                    >
                      {dataOne?.map((item, i) => (
                        <div key={i}>
                          <img src={item.img} alt="bannerart" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="product-detail-right">
                    <Slider
                      {...slider2Settings}
                      ref={(slider) => setNav2(slider)}
                    >
                      {dataTwo?.map((item, i) => (
                        <div key={i}>
                          <img src={item.img} alt="banner" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="product-wrapper-content">
                  <h1>{productDetail?.prd_name}</h1>
                  <div className="detail-icon">
                  <StarRating rating={5} />
                    <span className="">
                      <NavLink>(11 total reviews)</NavLink>
                    </span>
                  </div>
                  <div className="delivery-wrapper">
                    <div>
                      <img src={img7} alt="datOne" />
                      <p>Delivery Guaranteed</p>
                    </div>
                    <div>
                      <img src={img8} alt="datTwo" />
                      <p>Secure Transactions</p>
                    </div>
                    <div>
                      <img src={img9} alt="datThree" />
                      <p>Easy order Tracking</p>
                    </div>
                    <div>
                      <img src={img10} alt="datFour" />
                      <p>5 star rated</p>
                    </div>
                  </div>
                  <p className="my-3">{productDetail?.prd_desc}</p>
                  <p className="my-3">{productDetail?.prd_desc}</p>
                  <p>{productDetail?.prd_desc}</p>
                  <div className="product-detail-net my-3">
                    <span className="fw-normal">Net Weight-</span>
                    <span>
                      <strong>
                        {productDetail?.qty}
                        {productDetail?.msr_unit}
                      </strong>
                    </span>
                  </div>
                  <div className="product-badges">
                    {/* <span className="fw-normal badgeOne mr-10">500gm</span> */}
                    <span className="fw-normal badgeTwo">
                      {productDetail?.qty}
                      {productDetail?.msr_unit}
                    </span>
                  </div>
                  <div className="product-price-content">
                    <span className="priceOne">
                      Rs{productDetail?.discounted_price}
                    </span>
                    <span className="cut-price-One">
                      Rs{productDetail?.price}
                    </span>
                  </div>
                  <div className="product-cart-button-wrapper ">
                    {/* <div className="count-wrapper">
                            <span onClick={()=>setCount(prevCount => prevCount + 1)}>+</span>
                            <span>{count}</span>
                            <span onClick={()=>setCount(prevCount => Math.max(prevCount - 1, 0))}>-</span>
                        </div> */}
                    <div>
                      <button
                        className="btns"
                        onClick={() => handleAddCart(productDetail)}
                      >
                        Add to Cart <i className="fa fa-shopping-cart"></i>
                      </button>
                    </div>
                  </div>
                  <div className="purDetailSection my-5">
                    <p>Pure.Raw.Natural</p>
                    <div className="pureDetailWrapper">
                      <div className="pure-wrapper">
                        <img src={img14} alt="dataOne " />
                        <span className="fw-normal">
                          No processing for taste!{" "}
                        </span>
                      </div>
                      <div className="pure-wrapper">
                        <img src={img16} alt="dataOne " />
                        <span className="fw-normal">
                          Focussed Bee Conservation{" "}
                        </span>
                      </div>
                      <div className="pure-wrapper">
                        <img src={img15} alt="dataOne " />
                        <span className="fw-normal">Gentle bee colony </span>
                      </div>
                      <div className="pure-wrapper">
                        <img src={img12} alt="dataOne " />
                        <span className="fw-normal">
                          No heating to cut corners{" "}
                        </span>
                      </div>
                      <div className="pure-wrapper">
                        <img src={img13} alt="dataOne " />
                        <span className="fw-normal">Sustainable Farming </span>
                      </div>
                      <div className="pure-wrapper">
                        <img src={img11} alt="dataOne " />
                        <span className="fw-normal">
                          100% pure, no additives{" "} 
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <button className="btn btn-dark btn-lg text-white my-3" style={{width:"100%",height:"50px"}}>Buy Now</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductList />
      <MidSection />
      <Testimonial />
      <Footer />
    </>
  );
};

export default ProductDetailPage;
