import React, { useState } from "react";
import axios from "axios";
import { REACT_BASE_URL } from "../config";
import { NavLink, useNavigate } from "react-router-dom";
const SearchFilter = () => {
  const [searchFilter, setSerachFilter] = useState("");
  const navigate=useNavigate();
  const searchChange = (e) => {
    setSerachFilter(e.target.value);
  };
  const loadSearch=async()=>{

    try {
      const response = await axios.post(
        `${REACT_BASE_URL}/products-list?search`,
        searchFilter
      );
   
      if (response.status === 200) {
      
        navigate(`/shop?search=${searchFilter}`)
      }else{
        setSerachFilter("");
      }
    } catch (error) {
   
    }
  }
  const handleSearch =  () => {
    loadSearch();
  };
  return (
    <div className="search-wrapper">
      <input
        name="q"
        type="search"
        autoComplete="off"
        placeholder="Search here..."
        value={searchFilter}
        onChange={searchChange}
      />
      <span>
        <NavLink to="#">
          <i className="fa fa-search searchClass" onClick={handleSearch} />
        </NavLink>
      </span>
    </div>
  );
};

export default SearchFilter;
