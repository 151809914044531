import React from "react";
import img from "../assets/img/pexels-anete-lusina-5247989.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Story = ({storySlider}) => {
  const storyValue = storySlider && storySlider.about_us ? storySlider.about_us : {};
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
  };
  let storyData = [
    {
      id: 1,
      heading1: storyValue.yrs,
      paraData:storyValue.yrs_content
   },
    {
      id: 2,
      heading1: storyValue.yrs2,
      paraData:storyValue.yrs2_content    },
    {
      id: 3,
      heading1: storyValue.yrs3,
      paraData:storyValue.yrs3_content   },
    {
      id: 4,
      heading1: storyValue.yrs4,
      paraData:storyValue.yrs4_content   },
  ];
  return (
    <>
      <div className="section-padding">
        <div className="section-heading-wrapper">
          <h1 className="heading-title">OUR BEST StORY</h1>
          <p className="paratitle">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Repellendus sed officiis explicabo. Quibusdam blanditiis quis,
            officia quos placeat illum consectetur corrupti nisi suscipit?
            Minima doloremque odit tempora quia eligendi laudantium!
          </p>
        </div>
        <div className="container my-5">
          <div className="col-md-6 col-12">
            <img src={img} alt="data1" className="border-img-wrapper"/>
          </div>
          <div className="col-md-6 col-12">
            <Slider {...settings}>
              {storyData.map((item) => (
                <div key={item.id} className="data-container">
                  <h2 className="font-wt">{item?.heading1}</h2>
                  <p>{item?.paraData}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Story;
