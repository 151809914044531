import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { REACT_BASE_URL } from "../config";
import img1 from "../assets/img/logoPng.png";
import useStore from "../zustand/index";
import SearchFilter from "../pages/SearchFilter";
import axios from "axios";
const Header = () => { 
  const [hideShow, setHideShow] = useState(false);
const navigate=useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userName,setUserName]=useState("");
  const token = useStore(state => state.token);
  const setToken = useStore(state => state.setToken);
  const cartData = useStore((state) => state.cartData);
  const setCartData = useStore((state) => state.setCartData);
  const [cartQuantity, setCartQuantity] = useState(0);
  const fetchData = async () => {
    try {
      const response = await axios.post(`${REACT_BASE_URL}/cart-list`,cartData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accepts: "application/json"
        }
      });
      if (response.status === 200) {
        setCartData(response?.data?.cartData);
      }
    } catch (error) { 
      console.log("error==============>", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const totalQuantity = cartData.reduce(
      (total, item) => total + item.qty,
      0
    );
    setCartQuantity(totalQuantity);
  }, [cartData]);
  const burgerHandle = () => {
    setHideShow(!hideShow);
  };
  const closeHandle = () => {
    setHideShow(false);
  };


const fetchUserProfile = async () => {
  try {
    
    if (token) {
      setLoggedIn(true);
      const response = await axios.post(`${REACT_BASE_URL}/get-user-profile`, null,{
        headers: {
          Authorization: `Bearer ${token}`,
          Accepts:"application/json"
        },
      });
      if(response.status===200){
      setUserName(response.data.data.name);
      }
    }
   
  } catch (error) {
      console.error('Error fetching cart list:', error.message);
  } 
};  

useEffect(()=>{
  fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])


  const handleLogout = async () => {
   
    try {
      setToken('');
      setLoggedIn(false);
      // setCartQuantity('');
    
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  
  return (
    <div>
      <header className="navbar-wrapper">
        <nav className="navbar-headers bg-light">
          <div className="logo">
            <NavLink to="#">
              <img src={img1} width="70px" height="70px" alt="logo" />
            </NavLink>
          </div>
          <div className="navbar-items-list">
            <ul>
              <li>
                <NavLink to="/" className="">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/shop">Shop</NavLink>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact Us</NavLink>
              </li>
            </ul>
          </div>

          <div className="product-icons">
                 <SearchFilter />
            <span className="user-sectiion">
              {isLoggedIn ? (
                <>
                 <NavLink to="#">
                    <i className="fa fa-user" />
                  </NavLink>
                  <div className="user-container">
                    <div>
                      <NavLink to="/profile" >{userName}</NavLink>
                    </div>
                    <div>
                      <NavLink onClick={handleLogout}>LogOut</NavLink>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <NavLink to="#">
                    <i className="fa fa-user" />
                  </NavLink>
                  <div className="user-container">
                    <div>
                      <NavLink to="/login">Log In</NavLink>
                    </div>
                    <div>
                      <NavLink to="/register">Register</NavLink>
                    </div>
                  </div>
                </>
              )}
            </span>
            <span>
              <NavLink to="/cart">
                <span className="cart-badge">{cartQuantity}</span>
                <i className="fa fa-shopping-cart shoppingCart" />
              </NavLink>
            </span>
          </div>
          <div className="buger-btn" onClick={burgerHandle}>
            <span />
            <span />
            <span />
          </div>
        </nav>

        <div className={hideShow ? "sidbarNavList" : "sideBarvisable"}>
          <span className="closeItems" onClick={closeHandle}>
            X
          </span>
          <ul>
            <li>
              <NavLink to="/" className="active">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/shop" className="active">
                Shop
              </NavLink>
            </li>

            <li>
              <NavLink to="/about" className="active">
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="active">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
