// StarRating.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const StarRating = ({ rating }) => {

  const roundedRating = Math.round(rating);
  const stars = Array.from({ length: roundedRating }, (_, index) => index + 1);

  return (
    <div className="star-rating">
      {stars.map((star) => (
        <FontAwesomeIcon icon={faStar} key={star} />
      ))}
    </div>
  );
};

export default StarRating;
