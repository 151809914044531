import React from "react";
import StarRating from "./StarRating";
import {BASE_URL} from "../config";
import { NavLink } from "react-router-dom";


const BestSeller = ({bestSeller}) => {
  const bestSellerValue=bestSeller.best_seller_product;

  if (!bestSellerValue) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="section-padding-two">
        <div className="section-heading-wrapper">
          <h1 className="heading-title">OUR BEST SELLER</h1>
          <p className="paratitle">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Repellendus sed officiis explicabo. Quibusdam blanditiis quis,
            officia quos placeat illum consectetur corrupti nisi suscipit?
            Minima doloremque odit tempora quia eligendi laudantium!
          </p>
        </div>
        <div className="container mt-4">
          <div className="row">
            {bestSellerValue.map((items) => (
              <div className="col-lg-6 col-sm-12" key={items.id}>
                <div className="our-seller-wrapper text-center">
                  <div className="img-section">
                  <img src={`${BASE_URL}/${items.image}`} alt="banner" />
                  </div>
                  <div className="content-wrapper">
                    <p>{items.prd_name}</p>
                    {/* <span>{items.spanPara}</span> */}
                    <div>
                    <StarRating rating={items.total_review} />
                    </div>
                  </div>
                  <div>
                    <NavLink href="#" className="btns text-white m-auto">
                    Shop Now
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BestSeller;
