import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/ContactUs";
import Shop from "../pages/Shop";
import ProductDetailPage from "../pages/ProductDetailPage";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Terms from "../pages/Terms";
import Policy from "../pages/Policy";
import Cart from "../cart/Cart";
import Address from "../cart/Address";
import Payment from "../cart/Payment";
import ForgetPassword from "../accounts/ForgetPassword";
import Profile from "../components/Profile";
import  ProtectedRoute from "./ProtectedRoute";
import ChangePasssword from "../accounts/ChangePasssword";
// import PublicRoutes from "./PublicRoutes";

const Router = () => {
  return (
    <>
  
      <Routes>
        <Route  path="/" element={<Home />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route exact path="/shop" element={<Shop />}></Route>
        <Route exact path="/productdetail/:slug" element={<ProtectedRoute><ProductDetailPage /></ProtectedRoute>}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/register" element={<Register />}></Route>
        <Route exact path="/terms" element={<Terms />}></Route>
        <Route exact path="/policy" element={<Policy />}></Route>
  
        <Route exact path="/cart" element={<ProtectedRoute><Cart /></ProtectedRoute>}></Route>
        <Route exact path="/address" element={<ProtectedRoute><Address /></ProtectedRoute>}></Route>
        <Route exact path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>}></Route>
        <Route exact path="/forget-password" element={< ForgetPassword/>}></Route>
       
        <Route exact path="/change-password" element={<ProtectedRoute><ChangePasssword /></ProtectedRoute>}></Route>
        <Route exact path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>}></Route>
       
      </Routes>
     
    </>
  );
};

export default Router;
