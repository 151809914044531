import React from 'react'
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <>
        <footer className="footer-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div>
              <h4>Contacts</h4>
              <div>
                <span>
                  <i className="fa fa-home" />
                  Lorem ipsum dolor
                </span>
              </div>
              <div>
                <span>
                  <i className="fa fa-phone" />
                  Lorem ipsum dolor
                </span>
              </div>
              <div>
                <span>
                  <i className="fa fa-envelope-o" />
                  Lorem ipsum dolor
                </span>
              </div>
              <div>
                <span>
                  <i className="fa fa-globe" />
                  Lorem ipsum dolor
                </span>
              </div>
              <div className="icon-container">
                <span>
                  <NavLink  to="#">
                    <i className="fa fa-instagram" />
                  </NavLink>
                </span>
                <span>
                  <NavLink  to="#">
                    <i className="fa fa-facebook" />
                  </NavLink>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <h4>Informations</h4>
            <p>
              <strong>Lorem ipsum dolor</strong>
              <br />
              #27A, 20th Cross Rd, MCECHS Layout, Dr.Shivaram Karanth Nagar,
              Bengaluru, Karnataka 560077
              <br />
            </p>
            <p>
              <strong>Lorem ipsum dolor</strong>
              <br />
              (from 10AM to 7PM)
            </p>
            <p>
              <strong>Lorem ipsum dolorfeaeasfwrgfsd</strong>
            </p>
          </div>
          <div className="col-lg-4">
            <div>
              <div>
                <h4>Business Enquiries</h4>
              </div>
              <div>
                <span>
                  <NavLink  to="#">Bulk Honey </NavLink>
                </span>
              </div>
              <div>
                <span>
                  <NavLink  to="/terms">Terms & Conditions</NavLink>
                </span>
              </div>
              <div>
                <span>
                  <NavLink  to ="/policy">Privacy & Policy </NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Footer
