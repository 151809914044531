import React from 'react';
import {BASE_URL} from '../config';
import { NavLink } from 'react-router-dom';

const Looking = ({ nectorData }) => {
  const  nectar  = nectorData.nectar;

  return (
    <div className="section-padding">
      <div className="container looking-wrapper">
        <div className="row gx-1">
          {
            nectar && (<><div className="col-lg-6 col-md-6 col-sm-12">
            <img src={`${BASE_URL}/${nectar.image}`} alt="banner" className="img-fluid border-img-wrapper" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
            <div className="wrapper-inner">
              <h1>{nectar.title}</h1>
              <p>
              {nectar.description}
              </p>
              <NavLink to="/shop" className="btns text-white m-auto">
                Show More
              </NavLink>
            </div>
          </div></>) 
          }
          
        </div>
      </div>
    </div>
  );
};

export default Looking;
